import React from "react";
import $ from "jquery";
import FAforUpdate from "./pages/FAforUpdate";

window.jQuery = $;
window.$ = $;
global.jQuery = $;




// DashBoard Routes
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Dashboard1 = React.lazy(() => import("./pages/Dashboard1"));
const dashboard20 = React.lazy(() => import("./pages/dashboard20"));//dashboard20
// const Chat = React.lazy(() => import("./views/applications/message"));
const TalkToUs = React.lazy(() => import("./pages/TalkToUs"));


const InvestmentDashboard = React.lazy(() => import("./pages/InvestmentDashboard"));

const ProjectDetails = React.lazy(() => import("./pages/ProjectDetails"));

// Monitoring
const DemandSaturation = React.lazy(() => import("./pages/DemandSaturation"));
const DemandSaturation1 = React.lazy(() => import("./pages/DemandSaturation1"));

// const DemandSaturationstatewise = React.lazy(() => import("./pages/DemandSaturationstatewise"));



// Reports Routes
const AtAGlance = React.lazy(() => import("./pages/AtAGlance"));
const AtAGlanceNew = React.lazy(() => import("./pages/AtAGlanceNew"));
const PhysicalAndFinancialProgress = React.lazy(() => import("./pages/PhysicalAndFinancialProgress"));
const PhysicalAndFinancialProgressDynamic = React.lazy(() => import("./pages/PhysicalAndFinancialProgress(Dynamic)"));
const PhysicalAndFinancialProgressDynamic2 = React.lazy(() => import("./pages/PhysicalAndFinancialProgress(Dynamic)2"));
const PhysicalAndFinancialProgressAllIndiaConstWise = React.lazy(() => import("./pages/PhysialAndFinancialAllIndiaConstWise.js"));
const PhysicalProgressULBWise = React.lazy(() => import("./pages/PhysicalProgressULBWise"));
const DistrictWisePhysicalAndFinancialProgress = React.lazy(() => import("./pages/DistrictWisePhysicalAndFinancialProgress"));
const DistrictWisePhysicalAndFinancialProgressDynamic = React.lazy(() => import("./pages/DistrictWisePhysicalAndFinancialProgress(Dynamic)"));
const constituencywisePhysicalAndFinancialProgress = React.lazy(() => import("./pages/constituencywisePhysicalAndFinancialProgress"));
const CityWisePhysicalAndFinancialProgress = React.lazy(() => import("./pages/CityWisePhysicalAndFinancialProgress"));
const CityWisePhysicalAndFinancialProgressDynamic = React.lazy(() => import("./pages/CityWisePhysicalAndFinancialProgress(Dynamic)"));

const ClssCityWise = React.lazy(() => import("./pages/ClssCityWise"));
const ClssCityWiseYearWise= React.lazy(() => import("./pages/ClssCityWiseYearWise"));


const DistrictWiseProgress = React.lazy(() => import("./pages/DistrictWiseProgress"));
const CSMCWiseMonitoringProgress = React.lazy(() => import("./pages/CSMCWiseMonitoringProgress"));
const AtAGlance2 = React.lazy(() => import("./pages/AtaGlance2"));
const AtAGlance3 = React.lazy(() => import("./pages/AtaGlance3"));
const AuditDetails = React.lazy(() => import("./pages/AuditDetails"));


const ComparativeReport = React.lazy(() => import ("./pages/ComparativeReport"));
const CSMCwisereleasefund = React.lazy(() => import("./pages/CSMCwisereleasefund"));
const ReleaseAnalysis1 = React.lazy(() => import("./pages/ReleaseAnalysis1"));
const GenerateReleaseAnalysis = React.lazy(() => import("./pages/GenerateReleaseAnalysis"));

const FinancialAndPhysicalProgressRespectiveYears = React.lazy(() => import("./pages/FinancialAndPhysicalProgressRespectiveYears"));
const nonStarterHouses =  React.lazy(() => import("./pages/nonStarterHouses"));
const NonStarterHousesStateWise =  React.lazy(() => import("./pages/nonStarterHousesStateWise"));
const GeoTagAnalysis=React.lazy(() => import("./pages/GeoTagAnalysis"));
const StatusofHousesSanctioned=React.lazy(() => import("./pages/StatusofHousesSanctioned"));
const GeoTagTracking=React.lazy(() => import("./pages/GeoTagTracking"));
const GeoTagAVGPerformance=React.lazy(() => import("./pages/GeoTagAVGPerformance"));
const GeoTagAnalysis2=React.lazy(() => import("./pages/GeoTagAnalysis2"));
const GeoTagMonitoring=React.lazy(() => import("./pages/GeoTagMonitoring"));
const ExcesCABalance = React.lazy(() => import("./pages/ExcesCABalance"));
const ScoreCard1 = React.lazy(() => import("./pages/ScoreCard1"));
const ConsumptionsofMaterials = React.lazy(() => import("./pages/ConsumptionsofMaterials"));
const SltcCltcHfapoaDetails = React.lazy(() => import("./pages/SltcCltcHfapoaDetails"));


const NewTechnologies = React.lazy(() => import("./pages/NewTechnologies"));
const PPPReport =  React.lazy(() => import("./pages/PPPReport"));
const StateWisePPPReport =   React.lazy(() => import("./pages/StateWisePPPReport"));

const pminaugurated =  React.lazy(() => import("./pages/pminaugurated"));
const StateWisepminaugurated =   React.lazy(() => import("./pages/StateWisepminaugurated"));

const StateWiseNewTechnologies = React.lazy(() => import("./pages/StateWiseNewTechnologies"));

const RecalledAmountAdjusted = React.lazy(() => import("./pages/RecalledAmountAdjusted"));

const SurveyReport = React.lazy(() => import("./pages/SurveyReport"));

const ConstructionCost = React.lazy(() => import("./pages/ConstructionCost"));
const ExpectedRelease = React.lazy(() => import("./pages/ExpectedRelease"));
const HeadWiseExpectedRelease = React.lazy(() => import("./pages/HeadWiseExpectedRelease"));
const CategoriesWiseHouses = React.lazy(() => import("./pages/CategoriesWiseHouses"));
const CategoriesWiseHousesCasteAndReligion = React.lazy(() => import("./pages/CategoriesWiseHousesCasteWise.js"));
const CategoriesWiseHousesDistrict = React.lazy(() => import("./pages/CategoriesWiseHousesDistrict"));
const YearWisePhysical = React.lazy(() => import("./pages/YearWisePhysical"));//RespectiveYearWise
const RespectiveYearWise = React.lazy(() => import("./pages/RespectiveYearWise"));//financial
const slumBeneficiaries = React.lazy(() => import("./pages/SlumBeneficiaries"));
// /slumBeneficiaries
const respectiveYearWiseWithStatewise = React.lazy(() => import("./pages/respectiveYearWiseWithStatewise"));

const RespectiveYearWisefinancial = React.lazy(() => import("./pages/RespectiveYearWisefinancial"));//financial
const yearWiseReleaseVsUCReceived = React.lazy(() => import("./pages/yearWiseReleaseVsUCReceived"));
const yearWiseReleaseVsUCReceivedStateWise = React.lazy(() => import("./pages/yearWiseReleaseVsUCReceivedStateWise"));
const respectiveYearWiseWithStatewisefinancial = React.lazy(() => import("./pages/respectiveYearWiseWithStatewisefinancial"));
const cityWiseyearwisephysicalfinancial = React.lazy(() => import("./pages/cityWiseyearwisephysicalfinancial"));
const districtCityWiseProgress = React.lazy(() => import("./pages/DistrictCityWiseProgress"));
const YearWisePhysicalWithStatewise = React.lazy(() => import("./pages/YearWisePhysicalWithStatewise"));
const MasterPmayu = React.lazy(() => import("./pages/MasterPmayu"));

const FinancialContribution = React.lazy(() => import("./pages/FinancialContribution"));
const FinancialContributioncity = React.lazy(() => import("./pages/FinancialContributioncity"));
const PhysicalProgressDetails = React.lazy(() => import("./pages/PhysicalProgressDetails"));
const PhysicalProgressDetailscity = React.lazy(() => import("./pages/PhysicalProgressDetailscity"));
const FinancialProgressDetails = React.lazy(() => import("./pages/FinancialProgressDetails"));
//ajit

 const StatewiseProgressDetails = React.lazy(() => import("./pages/StatewiseProgressDetails"));
 const PartiallyStatewiseProgressDetails = React.lazy(() => import("./pages/PartiallyStatewiseProgressDetails"));
 const GeneralStatewiseProgressDetails =  React.lazy(() => import("./pages/GeneralStatewiseProgressDetails"));
 const GroundedStatewiseProgressDetails = React.lazy(() => import("./pages/GroundedStatewiseProgressDetails"));
const OccupancyStatewiseProgressDetails =  React.lazy(() => import("./pages/OccupancyStatewiseProgressDetails"));

const Statewiseucstatus = React.lazy(() => import("./pages/Statewiseucstatus"));
const ApprovedPAOLetter = React.lazy(() => import("./pages/ApprovedPAOLetter"));
const HeadWiseReleaseAndUC = React.lazy(() => import("./pages/HeadWiseReleaseAndUC"));
const InstallmentWiseReleaseAndUC = React.lazy(() => import("./pages/InstallmentWiseReleaseAndUC"));
const StateWiseCurtailedHouses = React.lazy(() => import("./pages/StateWiseCurtailedHouses"));
const StateWiseCurtailedHousesDetails = React.lazy(() => import("./pages/StateWiseCurtailedHousesDetails"));

const StatewiseucstatusCity = React.lazy(() => import("./pages/StatewiseucstatusCity"));
const NonProjectRelease=React.lazy(()=>import("./pages/NonProjectRelease"));
const NonProjectReleaseState=React.lazy(()=>import("./pages/NonProjectReleaseState"));

const SlumsDetails=React.lazy(()=>import("./pages/SlumsDetails"));
const SlumsCountReport=React.lazy(()=>import("./pages/SlumsCountReport"));
const housesPopulation=React.lazy(()=>import("./pages/housesPopulation"));
const SlumsDistrictDetails=React.lazy(()=>import("./pages/SlumsDistrictDetails"));
const SlumsDetailsProjectWise=React.lazy(()=>import("./pages/SlumsDetailsProjectWise"));
const FinancialAndPhysicalPerformance=React.lazy(()=>import("./pages/FinancialAndPhysicalPerformance"));
const FinancialAndPhysicalPerformanceStateWise=React.lazy(()=>import("./pages/FinancialAndPhysicalPerformanceStateWise"));
const FinancialProgressDetailscity = React.lazy(() => import("./pages/FinancialProgressDetailscity"));
const YearWiseFinancial = React.lazy(() => import("./pages/YearWiseFinancial"));
const YearWisePhysicalAndFinancial = React.lazy(() => import("./pages/YearWisePhysicalAndFinancial"));
const YearWiseFinancialWithStatewise = React.lazy(() => import("./pages/YearWiseFinancialWithStatewise"));
const ProjectDetailsReport = React.lazy(() => import("./pages/ProjectDetailsReport"));
const KPMG = React.lazy(() => import("./pages/KPMG"));
const Projectfeedback= React.lazy(() => import("./pages/Projectfeedback"));
const thememaster =  React.lazy(() => import("./pages/ThememasterDetails"));
const mainReportUpdate = React.lazy(() => import("./pages/mainReportUpdate"));
const AnnexureUpdate = React.lazy(() => import("./pages/AnnexureIdUpdate"));

const ProjectActionplan = React.lazy(() => import("./pages/ProjectActionplan"));

//const Maharastra = React.lazy(() => import("./pages/Maharastra"));
//Maharastra

// Weekly Reports Routes
const FinancialProgressWeekly = React.lazy(() => import("./pages/FinancialProgressWeekly"));
const FinancialProgressWeeklycity = React.lazy(() => import("./pages/FinancialProgressWeeklycity"));
const FinancialProgressWeeklyChandan = React.lazy(() => import("./pages/FinancialProgressWeekly copy"));
const PhysicalProgressWeekly = React.lazy(() => import("./pages/PhysicalProgressWeekly"));
const PhysicalProgressWeeklycity = React.lazy(() => import("./pages/PhysicalProgressWeeklycity"));
const BaseLinePMAYU = React.lazy(() => import("./pages/BaseLinePMAYU"));
const BaseLinePMAYUcity = React.lazy(() => import("./pages/BaseLinePMAYUcity"));
const StateWiseProgress = React.lazy(() => import("./pages/StateWiseProgress"));
const StateWiseProgressAllComp = React.lazy(() => import("./pages/StateWiseProgressAllComp"));
const StateWiseProgressAllCompcity = React.lazy(() => import("./pages/StateWiseProgressAllCompcity"));
const StateWiseProgressExCLSS = React.lazy(() => import("./pages/StateWiseProgressExCLSS"));
const StateWiseProgJN = React.lazy(() => import("./pages/StateWiseProgJN"));
const DistAndCityProgJN = React.lazy(() => import("./pages/DistAndCityProgJN"));
const StateWiseProgRAY = React.lazy(() => import("./pages/StateWiseProgRAY"));
const StateWiseProgRAYcity = React.lazy(() => import("./pages/StateWiseProgRAYcity"));
const StateWiseExRayCLSS = React.lazy(() => import("./pages/StateWiseExRayCLSS"));
const StateWiseExRayCLSScity=React.lazy(()=>import("./pages/StateWiseExRayCLSScity"));
const DemandStatus = React.lazy(() => import("./pages/DemandStatus"));
const StateWiseProgInc_CLSS_Exc_RAY = React.lazy(() => import("./pages/StateWiseProgInc_CLSS_Exc_RAY"));
const StateWiseProgressIncRayExCLSS = React.lazy(() => import("./pages/StateWiseProgressIncRayExCLSS"));
const StateWiseBaseLineInfo = React.lazy(() => import("./pages/StateWiseBaseLineInfo"));
const StateWiseBaseLineInfocity = React.lazy(() => import("./pages/StateWiseBaseLineInfocity"));
const AtaGlanceWeekly = React.lazy(() => import("./pages/AtaGlanceWeekly"));
const GeoTagBLC= React.lazy(() => import("./pages/GeoTagBLC"));//GeoTagDetails///weekly/mis-progressProject//MisProgressProject
const GeoTagLikelyDetails=React.lazy(() => import("./pages/GeoTagLikelyDetails.js"));
const GeoTagLikelyDetailsAHPISSR=React.lazy(() => import("./pages/GeoTagLikelyDetailsAHPISSR"));
const MisProgressProject= React.lazy(() => import("./pages/MisProgressProject"));
const ProjectWiseMisProgress= React.lazy(() => import("./pages/ProjectWiseMisProgress"));
const ComparisionReport= React.lazy(() => import("./pages/ComparisionReport"));

const PhysicalProgressMonitoring= React.lazy(() => import("./pages/PhysicalProgressMonitoring"));
const PhysicalProgressMonitoringNew= React.lazy(() => import("./pages/PhysicalProgressMonitoringNew"));
const GeoTagProgressTracking =  React.lazy(() => import("./pages/GeoTagProgressTracking"));
const GeoTagProgressTrackingStateWise =  React.lazy(() => import("./pages/GeoTagProgressTrackingStateWise"));
const ProjectWiseComparisionReport= React.lazy(() => import("./pages/ProjectWiseComparisionReport"));
// const StateWiseProgInc_CLSS_Exc_RAY = React.lazy(() => import("./pages/StateWiseProgInc_CLSS_Exc_RAY"));
const PhysicalProgressMonitoringStateWise =  React.lazy(() => import("./pages/PhysicalProgressMonitoringStateWise"));

const GeoTagNRSACountReport = React.lazy(() => import("./pages/GeoTagNRSACount"));

// Master Routes
const SchemeList = React.lazy(() => import("./pages/SchemeList"));
const ComponentList = React.lazy(() => import("./pages/ComponentList"));
const SubComponentList = React.lazy(() => import("./pages/SubComponentList"));
const MaterialList = React.lazy(() => import("./pages/MaterialList"));
const TechnologyList = React.lazy(() => import("./pages/TechnologyList"));

const StateList = React.lazy(() => import("./pages/StateList"));
const DistrictList = React.lazy(() => import("./pages/DistrictList"));
const CityList = React.lazy(() => import("./pages/CityList"));
const UDAMaster = React.lazy(() => import("./pages/udaMaster"));

const TechnologyMapping = React.lazy(() => import("./pages/TechnologyMapping"));
const ParliamentAndAssemblyMapping = React.lazy(() => import("./pages/ParliamentAndAssemblyMapping"));

const ClassificationList = React.lazy(() => import("./pages/ClassificationList"));
const ProgressInMapMaster = React.lazy(() => import("./pages/ProgressInMapMaster"));
const ThemeMaster = React.lazy(() => import("./pages/ThemeMaster"));
const ImplementingAgency = React.lazy(() => import("./pages/ImplementingAgency"));
const DocumentMaster = React.lazy(() => import("./pages/DocumentMaster"));
const ClassificationAdd_Update = React.lazy(() => import("./pages/ClassificationAdd_Update"));
const ClassificationMapping = React.lazy(() => import("./pages/ClassificationMapping"));
const ClassificationMapAddUpdate = React.lazy(() => import("./pages/ClassificationMapAddUpdate"));

const ConstituencyList = React.lazy(() => import("./pages/ConstituencyList"));
const AssemblyList = React.lazy(() => import("./pages/AssemblyList"));
const PAOLetterMaster = React.lazy(() => import("./pages/PAOLetterMaster"));
const LikelyCurtailmentMaster = React.lazy(() => import("./pages/LikelyCurtailment"));

// PRAYASH
const KPI5 = React.lazy(() => import("./pages/KPI5"));
const KPI3 = React.lazy(() => import("./pages/KPI3"));
const KPI14 = React.lazy(() => import("./pages/KPI14"));
const KPI22 = React.lazy(() => import("./pages/KPI22"));

//MIS
const dashBoardProjectWise = React.lazy(() => import("./pages/dashBoardProjectWise"));



// Entry Routes
const CLSSStateWise = React.lazy(() => import("./pages/CLSSStateWise"));
const CLSSStateWiseYearWise = React.lazy(() => import("./pages/CLSSStateWiseYearWise"));

const UploadCLSS = React.lazy(() => import("./pages/UploadCLSS"));
const UploadCLSSCityWise = React.lazy(() => import("./pages/UploadCLSSCityWise"));

const UploadCategoryWise = React.lazy(() => import("./pages/UploadCategoryWise"));
const ProjectInfo = React.lazy(() => import("./pages/ProjectInfo"));
const ReleasesFundFlow = React.lazy(() => import("./pages/ReleasesFundFlow"));
const ReleasesUpload = React.lazy(() => import("./pages/UploadRelease"));
const PhysicalProgress = React.lazy(() => import("./pages/PhysicalProgress"));
const PhysicalProgressSubmission = React.lazy(() => import("./pages/Forms/PhysicalProgressSubmission"));
const AddCumulativeRelease = React.lazy(() => import("./pages/Forms/AddCumulativeRelease"));
const UploadPhysicalProgress = React.lazy(() => import("./pages/UploadPhysicalProgress"));
const ReleaseForm = React.lazy(() => import("./pages/Forms/ReleaseForm"));
const ExcessCAPool = React.lazy(() => import("./pages/ExcessCAPool"));
const RecalledExcessCAPool = React.lazy(() => import("./pages/RecalledExcessCAPool"));
const clssSanctionStateWise = React.lazy(() => import("./pages/clssSanctionStateWise"));
const ProjectForm = React.lazy(() => import("./pages/Forms/ProjectForm"));
const AddImagesSiteVisit = React.lazy(() => import("./pages/AddImagesSiteVisit"));
const EditImagesSiteVisit = React.lazy(() => import("./pages/EditImagesSiteVisit"));
const UCSubmissionForm = React.lazy(() => import("./pages/Forms/UCSubmissionForm"));
const SanctionAndUtilization = React.lazy(() => import("./pages/SanctionAndUtilization"));
const audit = React.lazy(() => import("./pages/audit"));
const ProjectBriefDetails = React.lazy(() => import("./pages/ProjectBriefDetails"));
const UpdateProjectBriefDetails = React.lazy(() => import("./pages/UpdateProjectBriefDetails"));
const UpdateWorkOrder = React.lazy(() => import("./pages/UpdateWorkOrder"));

const Minutes = React.lazy(() => import("./pages/Minutes"));
const StateScoreForm = React.lazy(() => import("./pages/Forms/StateScoreForm"));
const CumulativeReleasePMAY = React.lazy(() => import("./pages/Forms/CumulativeReleasePMAY"));
const StatusDemandADD_Update = React.lazy(() => import("./pages/Forms/StatusDemandADD_Update"));
const DocumentUpload = React.lazy(() => import("./pages/Forms/DocumentUpload"));
const ImplementingAgencyMapWithProject = React.lazy(() => import("./pages/ImplementingAgencyMapWithProject"));
const ReleaseHeadInstallmentWise = React.lazy(() => import("./pages/ReleaseHeadInstallmentWise"));
const InstallmentHeadWise = React.lazy(() => import("./pages/InstallmentHeadWise"));
const ActualReleased = React.lazy(() => import("./pages/ActualReleased"));//ActualReleased
const LiabilityPMAY = React.lazy(() => import("./pages/Forms/LiabilityPMAY"));
const PAOLetter=React.lazy(()=>import("./pages/PAOLetter"));
const PAOList=React.lazy(()=>import("./pages/PAOList"));
const VIPReference = React.lazy(() => import("./pages/Forms/VipReference"));
// CMS
const CMSDashboard = React.lazy(() => import("./pages/Forms/CMSDashboard"));
const Shortfall = React.lazy(() => import("./pages/Forms/Shortfall"));

// CMS
const Users = React.lazy(() => import("./pages/Users"));
const usersActivity = React.lazy(() => import("./pages/usersActivity"));
const jurisdictionPermission=React.lazy(() => import("./pages/Forms/jurisdictionPermission"));
const usersActivityDetails = React.lazy(() => import("./pages/usersActivityDetails"));
const UserForm = React.lazy(() => import("./pages/Forms/UserForm"));
const InspectionHistory = React.lazy(() => import("./pages/InspectionHistory"));

const UserEntryReport = React.lazy(() => import("./pages/UserEntryReport"));
const TableRecordCount = React.lazy(() => import("./pages/TableRecordCount"));
const DataShortfall = React.lazy(() => import("./pages/DataShortfall"));
const DataShortfallCity = React.lazy(() => import("./pages/DataShortfallCity"));


const DataForUpdate = React.lazy(() => import("./pages/DataForUpdate"));
const GeoTagDataUpdates = React.lazy(() => import("./pages/GeoTagDataUpdates"));
const BeneficiaryDataUpdates = React.lazy(() => import("./pages/BeneficiaryDataUpdates"));
const MisbeneficiariesDataUpdates = React.lazy(() => import("./pages/MisbeneficiariesDataUpdates"));
const TestingMISBeneficiariesDataUpdates = React.lazy(() => import("./pages/TestingMISBeneficiariesDataUpdates"));
const BeneficiaryUpdateReport = React.lazy(() => import("./pages/BeneficiaryUpdateReport"));
const BeneficiaryCountCompare = React.lazy(() => import("./pages/BeneficiaryCountCompare"));

const BeneficiariesDetails = React.lazy(() => import("./pages/BeneficiariesDetails"));
const BeneficiaryNationalHousingBankPMAY_CLSS = React.lazy(() => import("./pages/NationalHousingBankPMAY_CLSS"));
const ProjectBrifShortfallDetails = React.lazy(() => import("./pages/ProjectBrifShortfallDetails"));

const Action = React.lazy(() => import("./pages/Action"));
const logDetails = React.lazy(() => import("./pages/logDetails"));
const API_Logs = React.lazy(() => import("./pages/API_Logs"));

const NotFound = React.lazy(() => import("./pages/NotFound"));
const YearWiseReleasedAndUCRecieved=React.lazy(()=>import("./pages/YearWiseReleasedAndUCRecieved"));
const stateReview=React.lazy(()=>import("./pages/stateReview"));//stateReview
const Profile=React.lazy(()=>import("./pages/Profile"));
// const DashboardDefault = React.lazy(() => import("./Demo/Dashboard/Default"));



const routes = [
  { path: "/", exact: true, name: "Default", component: Dashboard1 },
  { path: "/dashboard20", exact: true, name: "dashboard20", component: dashboard20 },
  { path: "/talk-to-us", exact: true, name: "TalkToUs", component: TalkToUs },
  
  { path: "/profile", exact: true, name: "Default", component: Profile },
  { path: "/new", exact: true, name: "Dashboard", component: Dashboard },
  
  { path: "/project-details/:projectCode", exact: true, name: "Default", component: ProjectDetails },
  // Map View
  { path: "/mapview/demand-saturation", exact: true, name: "Demand_Saturation", component:DemandSaturation1 },
  { path: "/mapview/demand-saturation1", exact: true, name: "Demand_Saturation", component:DemandSaturation },

  //Map view state wise details

  // { path: "/mapview/demand-saturation/statecode/:id", exact: true, name: "Demand_Saturation", component:DemandSaturationstatewise },

  
  // Reports Router
  {
    path: "/reports/at-glance",
    exact: true,
    name: "At a Glance",
    component: AtAGlance
  },
  {
    path: "/reports/at-glanceNew",
    exact: true,
    name: "At a Glance 2.0",
    component: AtAGlanceNew
  },
  {
    path: "/reports/physical-and-financial-progress",
    exact: true,
    name: "Physical & Financial Progress(Old)",
    component: PhysicalAndFinancialProgress
  },
  {
    path: "/reports/physical-and-financial-progress-dynm",
    exact: true,
    name: "Physical & Financial Progress",
    component: PhysicalAndFinancialProgressDynamic
  },
  {
    path: "/reports/physical-and-financial-progress-dynm2",
    exact: true,
    name: "Physical & Financial Progress2",
    component: PhysicalAndFinancialProgressDynamic2
  },
  {
    path: "/reports/physical-and-financial-progress-constituencywise-all-india",
    exact: true,
    name: "PhysicalAndFinancialAllIndiaConstWise",
    component: PhysicalAndFinancialProgressAllIndiaConstWise
  },
  {
    path: "/reports/physical-progress-ULB",
    exact: true,
    name: "Physical Progress ULB",
    component: PhysicalProgressULBWise
  },
  {
    path: "/reports/physical-and-financial-progress/state/:id",
    exact: true,
    name: "District wise Physical & Financial Progress",
    component: DistrictWisePhysicalAndFinancialProgress
  },
  {
    path: "/reports/physical-and-financial-progress-dynm/state/:id",
    exact: true,
    name: "District wise Physical & Financial Progress",
    component: DistrictWisePhysicalAndFinancialProgressDynamic
  },
  {
    path: "/reports/physical-and-financial-progress/constituencywise/:id",
    exact: true,
    name: "District wise Physical & Financial Progress",
    component: constituencywisePhysicalAndFinancialProgress
  },


  {
    path: "/reports/physical-and-financial-progress/district/:id",
    exact: true,
    name: "City wise Physical & Financial Progress",
    component: CityWisePhysicalAndFinancialProgress
  },
  {
    path: "/reports/physical-and-financial-progress-dynm/district/:id",
    exact: true,
    name: "City wise Physical & Financial Progress",
    component: CityWisePhysicalAndFinancialProgressDynamic
  },
  {
    path: "/reports/clss-statewise",
    exact: true,
    name: "CLSS State/CNA Wise",
    op: "report",
    component: CLSSStateWise
  },
  {
    path: "/reports/CLSSStateWiseYearWise",
    exact: true,
    name: "CLSS State CNA  And Year Wise",
    op: "report",
    component: CLSSStateWiseYearWise
  },
  {
    path: "/reports/ClssCityWise",
    exact: true,
    name: "ClssCityWise",
    op: "report",
    component: ClssCityWise
  },
  {
    path: "/reports/ClssCityWiseYearWise",
    exact: true,
    name: "ClssCityWiseYearWise",
    op: "report",
    component: ClssCityWiseYearWise
  },
  {
    path: "/reports/FAforUpdate",
    exact: true,
    name: "FAforUpdate",
    op: "report",
    component: FAforUpdate
  },

  {
    path: "/reports/district-wise-progress",
    exact: true,
    name: "District wise Progress",
    op: "report",
    component: DistrictWiseProgress
  },
  {
    path: "/reports/csmc-wise-monitoring-progress",
    exact: true,
    name: "CSMC wise Monitoring of Progress",
    component: CSMCWiseMonitoringProgress
  },
  {
    path: "/reports/at-glance-2",
    exact: true,
    name: "At a Glance 2",
    component: AtAGlance2
  },
  
  {
    path: "/reports/at-glance-3",
    exact: true,
    name: "At a Glance 3",
    component: AtAGlance3
  },
  {
    path: "/reports/auditDetails",
    exact: true,
    name: "AuditDetails",
    component: AuditDetails
  },
  {
    path: "/reports/comparative-report",
    exact: true,
    name: "Comparative Report",
    component: ComparativeReport
  },
  {
    path: "/reports/csmc-wise-release-fund",
    exact: true,
    name: "CSMCwisereleasefund",
    component: CSMCwisereleasefund
  },
  {
    path: "/reports/release-analysis",
    exact: true,
    name: "ReleaseAnalysis1",
    component: ReleaseAnalysis1
  },
  {
    path: "/reports/generate-release-analysis",
    exact: true,
    name: "GenerateReleaseAnalysis",
    component: GenerateReleaseAnalysis
  },
  {
    path: "/reports/status-financial-physical-progress-respective-years",
    exact: true,
    name: "Status of Progress in Respective Years",
    component: FinancialAndPhysicalProgressRespectiveYears
  },
  {
    path: "/reports/GeoTagAnalysis",
    exact: true,
    name: "Analysis of GeoTag",
    component: GeoTagAnalysis
  },
  {
    path: "/reports/StatusofHousesSanctioned",
    exact: true,
    name: "Status of Houses Sanctioned",
    component: StatusofHousesSanctioned
  },

  {
    path: "/reports/GeoTagTracking",
    exact: true,
    name: "GeoTag Tracking",
    component: GeoTagTracking
  },
  {
    path: "/reports/GeoTagAVGPerformance",
    exact: true,
    name: "GeoTag Tracking",
    component: GeoTagAVGPerformance
  },
  {
    path: "/reports/GeoTagNRSACountReport",
    exact: true,
    name: "GeoTag NRSA Count ",
    component: GeoTagNRSACountReport
  },
  {
    path: "/reports/GeoTagAnalysis2",
    exact: true,
    name: "GeoTag Analysis2",
    component: GeoTagAnalysis2
  },
  {
    path: "/reports/GeoTagMonitoring",
    exact: true,
    name: "GeoTag Monitoring",
    component: GeoTagMonitoring
  },
  {
    path: "/reports/nonStarterHouses",
    exact: true,
    name: "Non Starter House",
    component: nonStarterHouses
  },{
    path: "/reports/nonStarterHouses/:id",
    exact: true,
    name: "Non Starter House State Wise",
    component: NonStarterHousesStateWise
  },
  { path: "/reports/investment", exact: true, name: "Default", component: InvestmentDashboard },
  { path: "/reports/exces-ca-balance", exact: true, name: "Default", component: ExcesCABalance },
  { path: "/reports/scorecard", exact: true, name: "Default", component: ScoreCard1 },
  { path: "/reports/consumptions-of-materials", exact: true, name: "consumptions-of-materials", component: ConsumptionsofMaterials },
  { path: "/reports/Sltc-Cltc-Hfapoa-Details", exact: true, name: "Sltc-Cltc-Hfapoa-Details", component: SltcCltcHfapoaDetails },

  
  { path: "/reports/NewTechnologies", exact: true, name: "NewTechnologies", component: NewTechnologies },

  { path: "/reports/PPPReport", exact: true, name: "PPPReport", component: PPPReport },
  
  { path: "/reports/pminaugurated", exact: true, name: "pminaugurated", component: pminaugurated },
  { path: "/reports/pminaugurated/:id", exact: true, name: "pminaugurated", component: StateWisepminaugurated },
  { path: "/reports/NewTechnologies/:id", exact: true, name: "StateWiseNewTechnologies", component: StateWiseNewTechnologies },
  { path: "/reports/PPPReport/:id", exact: true, name: "PPPReport", component: StateWisePPPReport },
  { path: "/reports/RecalledAmountAdjusted", exact: true, name: "RecalledAmountAdjusted", component: RecalledAmountAdjusted },

  

  { path: "/reports/construction-cost", exact: true, name: "consumptions-of-materials", component: ConstructionCost },
  { path: "/reports/expected-release", exact: true, name: "expected-release", component: ExpectedRelease },
  { path: "/reports/head-wise-expected-release", exact: true, name: "Head-Wise-expected-release", component: HeadWiseExpectedRelease },

  { path: "/reports/survey-report", exact: true, name: "SurveyReport", component: SurveyReport },
 
  { path: "/reports/categories-wise-houses", exact: true, name: "CategoriesWiseHouses", component: CategoriesWiseHouses },
  { path: "/reports/categories-wise-houses-caste-religion-wise", exact: true, name: "CategoriesWiseHousesCaste&Religion", component: CategoriesWiseHousesCasteAndReligion },
  { path: "/reports/categories-wise-houses/District/:id", exact: true, name: "CategoriesWiseHouses", component: CategoriesWiseHousesDistrict },
  { path: "/reports/year-wise-physical", exact: true, name: "YearWisePhysical", component: YearWisePhysical },
  { path: "/reports/respective-year-wise", exact: true, name: "RespectiveYearWise", component: RespectiveYearWise },
  { path: "/reports/slumBeneficiaries", exact: true, name: "RespectiveYearWise", component: slumBeneficiaries },

  { path: "/reports/respective-year-wise/:stateCode", exact: true, name: "RespectiveYear&tatewise", component: respectiveYearWiseWithStatewise },
  { path: "/reports/respective-year-wise-financial", exact: true, name: "RespectiveYearWisefinancial", component: RespectiveYearWisefinancial },
  { path: "/reports/cityWise-year-wise-physical-financial", exact: true, name: "cityWiseyearw isephysicalfinancial", component: cityWiseyearwisephysicalfinancial },
  
  { path: "/reports/districtCityWiseProgress", exact: true, name: "districtCityWiseProgress", component: districtCityWiseProgress },
 
  
  { path: "/reports/respective-year-wise-financial/:stateCode", exact: true, name: "RespectiveYear&tatewisefinancial", component: respectiveYearWiseWithStatewisefinancial },
  { path: "/reports/yearWiseReleaseVsUCReceived", exact: true, name: "yearWiseReleaseVsUCReceived", component: yearWiseReleaseVsUCReceived },
  { path: "/reports/yearWiseReleaseVsUCReceived/:stateCode", exact: true, name: "yearWiseReleaseVsUCReceivedStateWise", component: yearWiseReleaseVsUCReceivedStateWise },
 
  { path: "/reports/year-wise-physical/:stateCode", exact: true, name: "YearWisePhysicalWithStatewise", component: YearWisePhysicalWithStatewise },
  { path: "/reports/master-pmayu", exact: true, name: "MasterPmayu", component: MasterPmayu },
  { path: "/reports/FA-for-Update", exact: true, name: "FAforUpdate", component: FAforUpdate },
  {
    path: "/reports/inspection-history",
    exact: true,
    name: "Inspection History",
    component: InspectionHistory
  },
  { path: "/reports/financial-contribution", exact: true, name: "FinancialContribution", component: FinancialContribution },
  { path: "/reports/physical-progress-details", exact: true, name: "PhysicalProgressDetails", component: PhysicalProgressDetails },
  { path: "/reports/financial-progress-details", exact: true, name: "FinancialProgressDetails", component: FinancialProgressDetails },
  { path: "/reports/year-wise-financial", exact: true, name: "YearWiseFinancial", component: YearWiseFinancial },
  { path: "/reports/year-wise-physical-and-financial", exact: true, name: "YearWisePhysicalAndFinancial", component: YearWisePhysicalAndFinancial },
  { path: "/reports/year-wise-financial/:stateCode", exact: true, name: "YearWiseFinancialWithStatewise", component: YearWiseFinancialWithStatewise },
  { path: "/reports/project-details-report", exact: true, name: "ProjectDetailsReport", component: ProjectDetailsReport },
 
  { path: "/reports/KPMG", exact: true, name: "KPMG", component: KPMG },


  { path: "/reports/project-action-plan", exact: true, name: "ProjectDetailsReport", component: ProjectActionplan },
  //layout work details-ajit

   { path: "/reports/StatewiseProgressDetails", exact: true, name: "StatewiseProgressDetails", component: StatewiseProgressDetails },
  
   { path: "/reports/StatewiseProgressDetails/general/:id", exact: true, name: "StatewiseProgressDetailsPartially", component: GeneralStatewiseProgressDetails },
  
   { path: "/reports/StatewiseProgressDetails/partially/:id", exact: true, name: "StatewiseProgressDetailsPartially", component: PartiallyStatewiseProgressDetails },
  
   { path: "/reports/StatewiseProgressDetails/grounded/:id", exact: true, name: "StatewiseProgressDetailsGrounded", component: GroundedStatewiseProgressDetails },
    
   { path: "/reports/StatewiseProgressDetails/occupancy/:id", exact: true, name: "StatewiseProgressDetailsOccupancy", component: OccupancyStatewiseProgressDetails },
  

   { path: "/reports/headwise-release-uc", exact: true, name: "HeadWiseReleaseAndUC", component: HeadWiseReleaseAndUC },
  { path: "/reports/approved-pao-letter", exact: true, name: "ApprovedPAOLetter", component: ApprovedPAOLetter },
  
  { path: "/reports/InstallmentWiseReleaseAndUC", exact: true, name: "InstallmentWiseReleaseAndUC", component: InstallmentWiseReleaseAndUC },
  { path: "/reports/Statewiseucstatus", exact: true, name: "Statewiseucstatus", component: Statewiseucstatus },
  
  {path:"/reports/state-review",exact:true,name:"stateReview",component:stateReview},
 

  {path:"/reports/YearWiseReleasedAndUCRecieved",exact:true,name:"YearWiseReleasedAndUCRecieved",component:YearWiseReleasedAndUCRecieved},
  { path: "/reports/StateWiseCurtailedHouses", exact: true, name: "StateWiseCurtailedHouses", component: StateWiseCurtailedHouses },

  { path: "/reports/StateWiseCurtailedHousesDetails/:id", exact: true, name: "StateWiseCurtailedHousesDetails", component: StateWiseCurtailedHousesDetails },

  { path: "/reports/Statewiseucstatus/city/:id", exact: true, name: "StatewiseucstatusCity", component: StatewiseucstatusCity },
  { path: "/reports/NonProjectRelease", exact: true, name: "NonProjectRelease", component: NonProjectRelease },
  { path: "/reports/NonProjectRelease/state/:id", exact: true, name: "NonProjectRelease", component: NonProjectReleaseState },
  { path: "/reports/SlumsDetails", exact: true, name: "SlumsDetails", component: SlumsDetails },
  { path: "/reports/SlumsCountReport", exact: true, name: "SlumsCountReport", component: SlumsCountReport },
  { path: "/reports/housesPopulation", exact: true, name: "housesPopulation", component: housesPopulation },
  { path: "/reports/SlumsDetails/District/:id", exact: true, name: "SlumsDistrictDetails", component: SlumsDistrictDetails },
  { path: "/reports/SlumsDetailsProjectWise", exact: true, name: "SlumsDetailsProjectWise", component: SlumsDetailsProjectWise },
  { path: "/reports/financialandphysical-performance", exact: true, name: "FinancialAndPhysicalPerformance", component: FinancialAndPhysicalPerformance },
  { path: "/reports/financialandphysical-performance/:stateCode", exact: true, name: "FinancialAndPhysicalPerformanceStateWise", component: FinancialAndPhysicalPerformanceStateWise },
  


  { path: "/reports/physical-progress-details/city/:id", exact: true, name: "PhysicalProgressDetailscity", component: PhysicalProgressDetailscity },
  { path: "/reports/financial-progress-details/city/:id", exact: true, name: "FinancialProgressDetailscity", component: FinancialProgressDetailscity },
  { path: "/reports/financial-contribution/city/:id", exact: true, name: "FinancialContributioncity", component: FinancialContributioncity },
 
  {
    path: "/weekly/physical-progress",
    exact: true,
    name: "Physical Progress (Vertical wise)",
    component: PhysicalProgressWeekly
  },
  {
    path: "/weekly/physical-progress/city/:id",
    exact: true,
    name: "Physical Progress (Vertical wise)",
    component: PhysicalProgressWeeklycity
  },
  {
    path: "/weekly/financial-progress",
    exact: true,
    name: "Financial Progress (Vertical wise)",
    component: FinancialProgressWeekly
  },
  {
    path: "/weekly/financial-progress/city/:id",
    exact: true,
    name: "Financial Progress (Vertical wise)",
    component: FinancialProgressWeeklycity
  },
  {
    path: "/weekly/financial-progress/1",
    exact: true,
    name: "Financial Progress (Vertical wise)",
    component: FinancialProgressWeeklyChandan
  },
  {
    path: "/weekly/statewise-exclude-rayclss",
    exact: true,
    name: "StatewiseexRAY",
    component: BaseLinePMAYU
  },
  {
    path: "/weekly/statewise-exclude-rayclss/city/:id",
    exact: true,
    name: "StatewiseexRAYcity",
    component: BaseLinePMAYUcity
  },
  {
    name: 'StateWiseProgressExclRay',
    exact: true,
    path: '/weekly/statewiseprogress-exclray',
    component: StateWiseProgress
  },
  {
    name: 'StateWiseProgressAllComp',
    exact: true,
    path: '/weekly/statewise-incl-ray-and-clss',
    component: StateWiseProgressAllComp
  },
  {
    name: 'StateWiseProgressAllComp',
    exact: true,
    path: '/weekly/statewise-incl-ray-and-clss/city/:id',
    component: StateWiseProgressAllCompcity
  },

  {
    name: 'StateWiseProgressExCLSS',
    exact: true,
    path: '/weekly/statewiseprogress-exclclss',
    component: StateWiseProgressExCLSS
  },
  {
    name: 'StateWiseProgJN',
    exact: true,
    path: '/weekly/jnnurm-state-wise',
    component: StateWiseProgJN
  },
  {
    name: 'DistAndCityProgJN',
    exact: true,
    path: '/weekly/jnnurm-dist-city-wise',
    component: DistAndCityProgJN
  },
  {
    name: 'StateWiseProgRAY',
    exact: true,
    path: '/weekly/ray-state-wise',
    component: StateWiseProgRAY
  },
  {
    name: 'StateWiseProgRAYcity',
    exact: true,
    path: '/weekly/ray-state-wise/city/:id',
    component: StateWiseProgRAYcity
  },
  {
    name: 'StateWiseExRayCLSS',
    exact: true,
    path: '/weekly/excl-ray-and-clss',
    component: StateWiseExRayCLSS
  },
  {
    name: 'StateWiseExRayCLSScity',
    exact: true,
    path: '/weekly/excl-ray-and-clss/city/:id',
    component: StateWiseExRayCLSScity
  },
  
  {
    name: 'DemandStatus',
    exact: true,
    path: '/weekly/demand-status',
    component: DemandStatus
  },
  {
    name: 'StateWiseProgInc_CLSS_Exc_RAY',
    exact: true,
    path: '/weekly/statewise-incl-clss-exc-ray',
    component: StateWiseProgInc_CLSS_Exc_RAY
  },
  {
    name: 'StateWiseProgressIncRayExCLSS',
    exact: true,
    path: '/weekly/statewise-incl-ray-exc-clss',
    component: StateWiseProgressIncRayExCLSS
  },
  {
    name: 'BaseLineInfo',
    exact: true,
    path: '/weekly/baseline-information',
    component: StateWiseBaseLineInfo
  },
  {
    name: 'BaseLineInfo',
    exact: true,
    path: '/weekly/baseline-information/city/:id',
    component: StateWiseBaseLineInfocity
  },
   {
    name: 'AtaGlanceWeekly',
    exact: true,
    path: '/weekly/at-glance',
    component: AtaGlanceWeekly
  },
  {
    name: 'GeoTagDetails',
    exact: true,
    path: '/weekly/GeoTagDetails',
    component: GeoTagBLC
  },
  {
    name: 'GeoTagLikelyDetails',
    exact: true,
    path: '/weekly/GeoTagLikelyDetails',
    component: GeoTagLikelyDetails
  },
  {
    name: 'GeoTagLikelyDetailsAHPISSR',
    exact: true,
    path: '/weekly/GeoTagLikelyDetailsAHPISSR',
    component: GeoTagLikelyDetailsAHPISSR
  },
  {
    name: 'MIS Progress of Project',
    exact: true,
    path: '/weekly/mis-progressProject',
    component: MisProgressProject
  },
  {
    name: 'MIS Progress of Project',
    exact: true,
    path: '/weekly/mis-progressProject/:id',
    component: ProjectWiseMisProgress
  },
  {
    name: 'Comparision-Repor',
    exact: true,
    path: '/weekly/comparision-Report',
    component: ComparisionReport
  },
  {
    name: 'PhysicalProgressMonitoring',
    exact: true,
    path: '/reports/PhysicalProgressMonitoring',
    component: PhysicalProgressMonitoring
  },
  {
    name: 'PhysicalProgressMonitoringNew',
    exact: true,
    path: '/reports/PhysicalProgressMonitoringNew',
    component: PhysicalProgressMonitoringNew
  },
  {
    name: 'GeoTagProgressTracking',
    exact: true,
    path: '/reports/GeoTagProgressTracking',
    component: GeoTagProgressTracking
  },
  {
    name: 'GeoTagProgressTrackingStateId',
    exact: true,
    path: '/reports/GeoTagProgressTracking/:id',
    component: GeoTagProgressTrackingStateWise
  },
  {
    name: 'Comparision-Repor',
    exact: true,
    path: '/weekly/comparision-Report/:id',
    component: ProjectWiseComparisionReport
  },
  {
    name: 'PhysicalProgressMonitoring',
    exact: true,
    path: '/reports/PhysicalProgressMonitoring/:id',
    component: PhysicalProgressMonitoringStateWise
  },
   // {
  //   name: 'DemandStatus',
  //   exact: true,
  //   path: '/weekly/demand-status',
  //   component: DemandStatus
  // },
  
  // marster Router
  {
    path: "/master/major-scheme",
    exact: true,
    name: "MajorScheme",
    component: SchemeList
  },
  {
    path: "/master/scheme-component",
    exact: true,
    name: "SchemeComponent",
    component: ComponentList
  },
  {
    path: "/master/sub-component",
    exact: true,
    name: "subComponent",
    component: SubComponentList
  },
  {
    path: "/master/technology",
    exact: true,
    name: "TechnologyList",
    component:TechnologyList
  },
  {
    path: "/master/material",
    exact: true,
    name: "Material",
    component: MaterialList
  },
  { path: "/master/state", exact: true, name: "States", component: StateList },
  {
    path: "/master/district",
    exact: true,
    name: "District",
    component: DistrictList
  },
  { path: "/master/city", exact: true, name: "City", component: CityList },
  { path: "/master/uda", exact: true, name: "UDA", component: UDAMaster },

  { path: "/master/project-technology-mapping", exact: true, name: "project-technology-mapping", component: TechnologyMapping },
  { path: "/master/parliament-assembly-mapping", exact: true, name: "parliament-assembly-mapping", component: ParliamentAndAssemblyMapping },
  
  { path: "/master/constituency", exact: true, name: "ConstituencyMaster", component: ConstituencyList },
  { path: "/master/assembly", exact: true, name: "AssemblyMaster", component: AssemblyList },
  { path: "/master/paoletter", exact: true, name: "PAOLetterMaster", component: PAOLetterMaster },
  
    
  { path: "/master/Classification", exact: true, name: "Classification", component: ClassificationList },
//   { path: "/master/classificationData/:id", exact: true, name: "Classification Master", component: ClassificationAddUpdate },
  //  { path: "/master/ClassificationAddUpdate/:id", exact: true, name: "ClassificationAddUpdate", component: ClassificationAddUpdate },
  //  { path: "/master/ClassificationAddUpdate", exact: true, name: "Classification Master", component: ClassificationAddUpdate },

 
   { path: "/master/ClassificationAdd_Update", exact: true, name: "Classification Master", component: ClassificationAdd_Update },
 
   { path: "/master/ClassificationAdd_Update/:id", exact: true, name: "Classification Master", component: ClassificationAdd_Update },



   { path: "/master/ClassificationMap", exact: true, name: "ClassificationMap", component: ClassificationMapping },
   { path: "/master/ClassificationMapAddUpdate/:id", exact: true, name: "ClassificationMapAddUpdate", component: ClassificationMapAddUpdate },
   { path: "/master/progress-in-map", exact: true, name: "progress-in-map", component:ProgressInMapMaster },
   { path: "/master/theme-master", exact: true, name: "ThemeMaster", component:ThemeMaster},
   { path: "/master/implementing-agency", exact: true, name: "ImplementingAgency", component:ImplementingAgency},
   { path: "/master/document-upload-master", exact: true, name: "DocumentMaster", component:DocumentMaster },
   { path: "/master/likely-curtailment-master", exact: true, name: "LikelyCurtailmentMaster", component:LikelyCurtailmentMaster },

  //  Prayash
   { path: "/prayash/5kpi", exact: true, name: "KPI5", component:KPI5 },
   { path: "/prayash/3kpi", exact: true, name: "KPI3", component:KPI3 },
   { path: "/prayash/14kpi", exact: true, name: "KPI14", component:KPI14 },
   { path: "/prayash/22kpi", exact: true, name: "KPI22", component:KPI22 },

   //MIS DashBoard
   { path: "/mis/dashBoardProjectWise", exact: true, name: "KPI5", component:dashBoardProjectWise },


  // data entry Router
  {
    path: "/entry/clss-statewise",
    exact: true,
    name: "CLSS State Wise",
    component: CLSSStateWise
  },
  {
    path: '/entry/clssSanctionStateWise',
    exact: true,
    name: "CLSS State Wise Sanction",
    component: clssSanctionStateWise
  },
  {
    path: "/entry/clss-citywise",
    exact: true,
    name: "Upload CLSS CityWise",
    component: UploadCLSSCityWise
  },
  {
    path: "/entry/UploadCategoryWise",
    exact: true,
    name: "Upload Category Wise",
    component: UploadCategoryWise
  },
  {
    path: "/entry/clss-statewise/upload",
    exact: true,
    name: "Upload CLSS",
    component: UploadCLSS
  },

  {
    path: "/reports/releases-headwise",
    exact: true,
    name: "Cumulative Release",
    component: CumulativeReleasePMAY
  },
  {
    path: "/reports/releases-yearwise",
    exact: true,
    name: "Cumulative Release",
    component: ReleaseHeadInstallmentWise
  },
  {
    path: "/reports/InstallmentHeadWise",
    exact: true,
    name: "Installment / Head Wise",
    component: InstallmentHeadWise
  },
  {
    path: "/reports/ActualReleased",
    exact: true,
    name: "Actual Released",
    component: ActualReleased
  },
  {
    path: "/reports/pmay-u-liability",
    exact: true,
    name: "Liability PMAY-U",
    component: LiabilityPMAY
  },
  
  {
    path: "/entry/project-information",
    exact: true,
    name: "Project Information",
    component: ProjectInfo
  },
  {
    path: "/entry/releases-fundflow",
    exact: true,
    name: "Releases & Fund Flow",
    component: ReleasesFundFlow
  },
  {
    path: "/entry/release/upload",
    exact: true,
    name: "Add Releases",
    component: ReleasesUpload
  },

  {
    path: "/entry/physical-progress",
    exact: true,
    name: "PhysicalProgress",
    component: PhysicalProgress
  },
  {
    path: "/entry/physical-progress-submission",
    exact: true,
    name: "PhysicalProgressSubmission",
    component: PhysicalProgressSubmission
  },
  {
    path: "/entry/add-cumulative-release",
    exact: true,
    name: "AddCumulativeRelease",
    component: AddCumulativeRelease
  },
  {
    path: "/entry/physical-progress/upload",
    exact: true,
    name: "UploadPhysicalProgress",
    component: UploadPhysicalProgress
  },
  {
    path: "/entry/releases-fundflow/add-update",
    exact: true,
    name: "ReleaseForm",
    component: ReleaseForm
  },
  {
    path: "/entry/excess-ca-pool",
    exact: true,
    name: "ExcessCAPool",
    component: ExcessCAPool
  },

  {
    path: "/entry/recalled-excess-ca-pool",
    exact: true,
    name: "RecalledExcessCAPool",
    component: RecalledExcessCAPool
  },
  {
    path: "/entry/uc-submission",
    exact: true,
    name: "UCSubmissionForm",
    component: UCSubmissionForm
  },
  {
    path: "/entry/sanction-utilization",
    exact: true,
    name: "SanctionAndUtilization",
    component: SanctionAndUtilization
  },
  {
    path: "/entry/audit",
    exact: true,
    name: "audit",
    component: audit
  },
  {
    path: "/entry/project-brief-details",
    exact: true,
    name: "ProjectBriefDetails",
    component: ProjectBriefDetails
  },
  {
    path: "/entry/project-brief-details/:projectCode/:tableId",
    exact: true,
    name: "UpdateProjectBriefDetails",
    component: UpdateProjectBriefDetails
  },
  {
    path: "/entry/update-work-order/:projectCode",
    exact: true,
    name: "UpdateWorkOrder",
    component: UpdateWorkOrder
  },
  
  {
    path: "/entry/minutes",
    exact: true,
    name: "Minutes",
    component: Minutes
  },
  {
    path: "/entry/scorecard",
    exact: true,
    name: "StateScoreForm",
    component: StateScoreForm
  },
  
  {
    path: "/entry/project-information/add",
    exact: true,
    name: "ProjectAdd",
    component: ProjectForm
  },
  {
    path: "/entry/project-information/:id",
    exact: true,
    name: "ProjectEdit",
    component: ProjectForm
  },
  {
    path: "/entry/demand-survey",
    exact: true,
    name: "StatusDemandADD_Update",
    component: StatusDemandADD_Update
  },
  {
    path: "/entry/document-upload",
    exact: true,
    name: "DocumentUpload",
    component: DocumentUpload
  },
  {
    path: "/entry/implementing-agency-project",
    exact: true,
    name: "ImplementingAgencyMapWithProject",
    component: ImplementingAgencyMapWithProject
  },
  { path: "/entry/paolist", exact: true, name: "PAOList", component: PAOList },
  { path: "/entry/paoletter", exact: true, name: "PAOLetter", component: PAOLetter },
  { path: "/entry/paoletter/:letterId", exact: true, name: "PAOLetter", component: PAOLetter },
  {
    path: "/entry/vip-reference",
    exact: true,
    name: "VIPReference",
    component: VIPReference
  },
  {
    path: "/entry/site-visit/:id",
    exact: true,
    name: "AddImagesSiteVisit",
    component: AddImagesSiteVisit
  },
  {
    path: "/entry/site-visit-edit/:id",
    exact: true,
    name: "EditImagesSiteVisit",
    component: EditImagesSiteVisit
  },
  // cms routers
  {
    path: "/cms/dashboard",
    exact: true,
    name: "CMSDashboard",
    component: CMSDashboard
  },
  {
    path: "/cms/shortfall",
    exact: true,
    name: "Shortfall",
    component: Shortfall
  },


  
  //Admin services
  {
    path: "/admin-services/users",
    exact: true,
    name: "Users",
    component: Users
  },
  {
    path: "/admin-services/jurisdictionPermission",
    exact: true,
    name: "jurisdictionPermission",
    component: jurisdictionPermission
  },

  {
    path: "/admin-services/usersActivity",
    exact: true,
    name: "usersActivity",
    component: usersActivity
  },
  {
    path: "/admin-services/usersActivityDetails/:id",
    exact: true,
    name: "usersActivityDetails",
    component: usersActivityDetails
  },

  {
    path: "/admin-services/users/create",
    exact: true,
    name: "User Form",
    component: UserForm
  },
  {
    path: "/admin-services/users/:id",
    exact: true,
    name: "UserForm",
    component: UserForm
  },
  {
    path: "/admin-services/user-entry",
    exact: true,
    name: "UserEntryReport",
    component: UserEntryReport
  },
  {
    path: "/admin-services/table-record-count",
    exact: true,
    name: "TableRecordCount",
    component: TableRecordCount
  },
  {
    path: "/admin-services/data-shortfall",
    exact: true,
    name: "DataShortfall",
    component: DataShortfall
  },
  {
    path: "/admin-services/data-shortfall/:id",
    exact: true,
    name: "DataShortfallCity",
    component: DataShortfallCity
  },
  {
    path: "/admin-services/data-for-update",
    exact: true,
    name: "DataForUpdate",
    component: DataForUpdate
  },
  {
    path: "/admin-services/geotag-data-updates",
    exact: true,
    name: "GeoTagDataUpdates",
    component: GeoTagDataUpdates
  },{
    path: "/admin-services/beneficiary-data-updates",
    exact: true,
    name: "BeneficiaryDataUpdates",
    component: BeneficiaryDataUpdates
  },{
    path: "/admin-services/MisbeneficiariesDataUpdates",
    exact: true,
    name: "MisbeneficiariesDataUpdates",
    component: MisbeneficiariesDataUpdates
  },{
    path: "/admin-services/TestingMISBeneficiariesDataUpdates",
    exact: true,
    name: "TestingMISBeneficiariesDataUpdates",
    component: TestingMISBeneficiariesDataUpdates
  },{
    path: "/admin-services/beneficiary-updates-report",
    exact: true,
    name: "BeneficiaryUpdateReport",
    component: BeneficiaryUpdateReport
  },{
    path: "/admin-services/BeneficiaryCountCompare",
    exact: true,
    name: "BeneficiaryCountCompare",
    component: BeneficiaryCountCompare
  },
  {
    path: "/admin-services/beneficiaries-details",
    exact: true,
    name: "Beneficiaries Details",
    component: BeneficiariesDetails
  },
  {
    path: "/admin-services/NHB_PMAY",
    exact: true,
    name: "Beneficiaries NHBPMAY",
    component: BeneficiaryNationalHousingBankPMAY_CLSS
  },
  {
    path: "/admin-services/project-shortfall-details",
    exact: true,
    name: "ProjectBrifShortfallDetails",
    component: ProjectBrifShortfallDetails
  },{
    path: "/admin-services/action",
    exact: true,
    name: "Action",
    component: Action
  },{
    path: "/admin-services/logDetails",
    exact: true,
    name: "logDetails",
    component: logDetails
  },
  { path: "/admin-services/api-log-details", exact: true, name: "Api Logs", component: API_Logs },
  { path: "/admin-services/project-feedback", exact: true, name: "Projectfeedback", component: Projectfeedback },
  { path: "/admin-services/mainReportUpdate", exact: true, name: "mainReportUpdate", component: mainReportUpdate },
  { path: "/admin-services/annexure-update", exact: true, name: "annexureUpdate", component: AnnexureUpdate },
  { path: "/admin-services/thememaster", exact: true, name: "thememaster", component: thememaster },
  
  {
    path: "*",
    exact: true,
    name: "NotFound",
    component: NotFound

  },
];

export default routes;