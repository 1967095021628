import React from "react";
import { Button, Row, Table,Col } from "react-bootstrap";
import { Loader } from "./Loader";
import { BASE_URL } from "../store/constant";
import Axios from "axios";
import map from "lodash/map";
import get from "lodash/get";
import find from "lodash/find";
import _ from 'lodash'
import { toast } from "react-toastify";
import styled from "styled-components";
import moment from "moment";
import $ from 'jquery'
import { Input } from "./";
import {exportTableToExcel} from '../utils'


const Wrapper = styled.div`
  position: relative;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  & h3 {
    text-align: center;
    display: none;
  }

  @media print {
    h3 {
      display: block;
    }
  }
`;

class ForgotPassword extends React.Component {
  state = {
    isLoading: true,
    otpsend:''
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    this.setState({ userName:get(this.props, "userName"), isLoading: false });
  
  };

  render() {
    const { isLoading,otpsend,userName} = this.state;
  

    return (
      <Wrapper>
        {isLoading ? (
          <>
           
          </>
        ) : (
          <div style={{ width: "100%"}} className="bg-c-white customTable" id="MonitoringofProgress">
          
            <div style={{ width: "100%"}}>
                      <Col md={12}>
                     
                        <><Row>
                        <Col  md={12} sm={12} xs={12} className="mb-2">
                          Enter OTP
                          </Col>
                      <Col  md={12} sm={12} xs={12}>
                        <Input
                       autoComplete="off"
                       type={"number"}
                       style={{textAlign:'left',background:'#fffdec'}}
                          id='otp'
                          defaultValue={''}
                          onChange={(e) => {

                          } } />
                          <div className="mb-3" style={{  color: "orangered",fontSize: 10, }}>(OTP has been sent on your registered Email & Mobile No )*</div>
                     
                      </Col>
                      </Row>
                      <Row>
                      <Col  md={12} sm={12} xs={12} className="mb-2">
                     Enter New Password
                          </Col>
                      <Col  md={12} sm={12} xs={12}>
                        <Input
                        autoComplete="off"
                          // label={'New Password.'}
                          id='newpassword'
                          type="password"
                          //defaultValue={obj.feedback}
                          defaultValue={''}
                          onChange={(e) => {
                            // this.setState({feedback:e,...obj})
                          } } />
                          <div className="mb-3" style={{  color: "orangered",fontSize: 10, }}>(Password must contain atleast one capital letter, one special character such as !@#$-*+?._=, atleast two numerals and should be atleast 8 characters long.)*</div>
                     
                      </Col>
                      </Row>
                    <Row>
                    <Col  md={12} sm={12} xs={12} className="mb-2">
                    Confirm New Password
                          </Col>
                        <Col  md={12} sm={12} xs={12}>
                          <Input
                          autoComplete="off"
                            // label={'Confirm Password.'}
                            id='confpassword'
                            type="password"
                            //defaultValue={obj.feedback}
                            defaultValue={''}
                            onChange={(e) => {
                              // this.setState({feedback:e,...obj})
                            } } />
                        </Col>
                        </Row>
                        <Row md={12} sm={12} xs={12}>
                        <Col md={6} sm={6} xs={6} >
                            
                          <Button

                            size={"sm"}
                            className="mb-0 mt-4 w-100" style={{ textAlign: "center", margin: 0, fontSize: 13, fontWeight: "Bolder", color: "white" }}
                            onClick={async () => {
                              const otp = document.getElementById('otp').value;
                              const NewPassword = document.getElementById('newpassword').value;
                              const Confirmpassword = document.getElementById('confpassword').value;
                              const pass_regex = /(?=^.{8,}$)(?=.*[A-Z])(?=.+[!@#$\-*+?._=])(?=.*\d.*\d)/;
                              const intRegex = /^\d+$/;
                             

                              if (!pass_regex.test(NewPassword)) {
                                toast.error('Password must contain atleast one capital letter, one special character such as !@#$-*+?._=, atleast two numerals and should be atleast 8 characters long.!');

                              }else if(!(intRegex.test(otp)) || otp.length>6){
                                toast.error('Please Enter 6 digit OTP!');
                              }
                              else if(otp===''){
                                toast.error('Please Enter OTP!');
                              }else if(NewPassword===''){
                                toast.error('Please Enter New Password !');
                              }else if(Confirmpassword===''){
                                toast.error('Please Enter Confirm Password!');
                              }else if(Confirmpassword!==NewPassword){
                                toast.error('New Password & Confirm Password Will be Same!');
                              }else{

                                await Axios
                                .post(`${BASE_URL}/users/validateChangePassword`, {'NewPassword':NewPassword,'Confirmpassword':Confirmpassword,'otp':otp,'userName':userName,})
                                .then((res) => {
                                  if (get(res, "data.otpRequired", false)) {
                                    toast.error(res.data.message);
                                  } else {
                                    toast.success(res.data.message);
                                    this.setState({ openModalForForgot: false })
                                    document.getElementById('otp').value=''
                               document.getElementById('newpassword').value=''
                              document.getElementById('confpassword').value=''
                              
                                  }
                                })
                                  .catch((err) => {
                                    toast.error(err.response.data.message);  
                             });

                              }

                            } }

                          >Submit</Button>
                        </Col>

                        <Col  md={6} sm={6} xs={6}>
                          <Button

                            size={"sm"}
                            className="mb-0 mt-4 w-100" style={{ textAlign: "center", margin: 0, fontSize: 13, fontWeight: "Bolder", color: "white" }}
                            onClick={async () => {
                              //'userName':userName
                             // console.log('forgot password');
                              const name=userName
                                        await Axios
                                        .post(`${BASE_URL}/users/usersverifySendOTP`, {'username':name, type:'forgotPassword'})
                                        .then((res) => {
                                          if (get(res, "data.otpRequired", false)) {
                                            toast.error(res.data.message);
                                          } else {
                                            toast.success(res.data.message);
                                            this.setState({ openModalForForgot:true ,userNameSet:name})
                                          }
                                        })
                                          .catch((err) => {
                                            toast.error(err.response.data.message);  
                                     });

                            } }

                          >Resent OTP</Button>
                        </Col>
                       


                      </Row></>


                          </Col>
                 

            </div>
          </div>
        )}
      </Wrapper>
    );
  }
}

export default ForgotPassword;
