import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import get from 'lodash/get'
import * as Cookies from 'js-cookie'

class PictorialChart extends Component {
  state={
    graphValueForCheck0:'',
    graphValueForCheck1:'',

    thememaster:'',
  }
  componentDidMount(){
    this.initMaleChart()

  }
  componentDidUpdate(){
    console.log('this.props-New->>',this.props);

    this.initMaleChart()
  }


  initMaleChart() {
    const theme = this.props.thememaster ? this.props.thememaster : {};
    
  
    am4core.useTheme(am4themes_animated);
 
    let data = [{
      "category": "Critical",
      "value": 89,
      "color": am4core.color("#68ab77"),
      "breakdown": [{
        "category": "AHP",
        "value": 29
      }, {
        "category": "BLC",
        "value": 40
      }, {
        "category": "ISSR",
        "value": 11
      }, {
        "category": "RAY",
        "value": 9
      }]
    }, {
      "category": "Acceptable",
      "value": 71,
      "color": am4core.color("#a0a9aa"),
      "breakdown": [{
        "category": "AHP",
        "value": 22
      }, {
        "category": "BLC",
        "value": 30
      }, {
        "category": "ISSR",
        "value": 11
      }, {
        "category": "RAY",
        "value": 10
      }]
    }, {
      "category": "Good",
      "value": 120,
      "color": am4core.color("#6b7477"),
      "breakdown": [{
        "category": "AHP",
        "value": 60
      }, {
        "category": "BLC",
        "value": 35
      }, {
        "category": "ISSR",
        "value": 15
      }, {
        "category": "RAY",
        "value": 10
      }]
    }]
    


   
      let chart = am4core.create(
      this.props.id || 'PictorialChart',
      get(this.props, 'graphType') ? am4charts.SlicedChart : am4charts.SlicedChart
    );
   
   
    chart.width = am4core.percent(100);
    chart.height = am4core.percent(100);
    chart.layout = "horizontal";
    
    
    /**
     * Column chart
     */
    
    // Create chart instance
    let columnChart = chart.createChild(am4charts.XYChart);
    
    // Create axes
    let categoryAxis = columnChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    
    let valueAxis = columnChart.xAxes.push(new am4charts.ValueAxis());
    
    // Create series
    let columnSeries = columnChart.series.push(new am4charts.ColumnSeries());
    columnSeries.dataFields.valueX = "value";
    columnSeries.dataFields.categoryY = "category";
    columnSeries.columns.template.strokeWidth = 0;
    
    /**
     * Pie chart
     */
    
    // Create chart instance
    let pieChart = chart.createChild(am4charts.PieChart);
    pieChart.data = data;
    pieChart.innerRadius = am4core.percent(50);
    
    // Add and configure Series
    let pieSeries = pieChart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.labels.template.disabled = true;
    
    // Set up labels
    let label1 = pieChart.seriesContainer.createChild(am4core.Label);
    label1.text = "";
    label1.horizontalCenter = "middle";
    label1.fontSize = 35;
    label1.fontWeight = 600;
    label1.dy = -30;
    
    let label2 = pieChart.seriesContainer.createChild(am4core.Label);
    label2.text = "";
    label2.horizontalCenter = "middle";
    label2.fontSize = 12;
    label2.dy = 20;
    
    // Auto-select first slice on load
    pieChart.events.on("ready", function(ev) {
      pieSeries.slices.getIndex(0).isActive = true;
    });
    
    // Set up toggling events
    pieSeries.slices.template.events.on("toggled", function(ev) {
      if (ev.target.isActive) {
        
        // Untoggle RAY slices
        pieSeries.slices.each(function(slice) {
          if (slice != ev.target) {
            slice.isActive = false;
          }
        });
        
        // Update column chart
        columnSeries.appeared = false;
        columnChart.data = ev.target.dataItem.dataContext.breakdown;
        columnSeries.fill = ev.target.fill;
        columnSeries.reinit();
        
        // Update labels
        label1.text = pieChart.numberFormatter.format(ev.target.dataItem.values.value.percent, "#.'%'");
        label1.fill = ev.target.fill;
        
        label2.text = ev.target.dataItem.category;
      }
    });
    

  }


  // shouldComponentUpdate(nextProps, nextState) {
  //   return (
  //     get(nextProps.graphData,'0.value') !== get(nextState,'graphValueForCheck0') ||
  //     get(nextProps.graphData,'1.value') !== get(nextState,'graphValueForCheck1') ||
  
  //     get(nextProps.thememaster,'id') !== get(nextState,'thememaster')
  //   );
  // }


    render() {
        return <div id={this.props.id ||'PictorialChart'} className={this.props.id ||'PictorialChart'} style={{width: '157%', height: this.props.height,position:'relative',left: '-259.188px',...this.props.style}} />;
    }
}

export default PictorialChart;