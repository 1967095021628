import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { async } from './../utils/index';
import get from 'lodash/get'
class PictorialChartInvested extends Component {
  state={
    totalCategory:0 ,
  }

  componentDidMount() {

    this.initChart();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.graphData !== this.props.graphData ||
      prevProps.SchemesdataFilterType !== this.props.SchemesdataFilterType
    ) {

      this.updateChartData();
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }


  initChart() {
    am4core.useTheme(am4themes_animated);

    this.chart = am4core.create(
      this.props.id || "PictorialChartInvested",
      am4charts.SlicedChart
    );

    this.chart.width = am4core.percent(100);
    this.chart.height = am4core.percent(100);
    this.chart.layout = "horizontal";

    // Create column chart
    this.columnChart = this.chart.createChild(am4charts.XYChart);

    let categoryAxis = this.columnChart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;

    let valueAxis = this.columnChart.xAxes.push(new am4charts.ValueAxis());

    let columnSeries = this.columnChart.series.push(new am4charts.ColumnSeries());
    columnSeries.dataFields.valueX = "value";
    columnSeries.dataFields.categoryY = "category";
    columnSeries.columns.template.strokeWidth = 0;
    columnSeries.columns.template.tooltipText = "{category}: {value}";

    // Create pie chart
    this.pieChart = this.chart.createChild(am4charts.PieChart);
    this.pieChart.innerRadius = am4core.percent(50);

    let pieSeries = this.pieChart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    pieSeries.slices.template.propertyFields.fill = "color";
    pieSeries.slices.template.tooltipText = "{category}: {value}";

    pieSeries.labels.template.disabled = true; // Disable labels
    pieSeries.ticks.template.disabled = true; // Disable connector line

    pieSeries.labels.template.disabled = false; // Enable 
    pieSeries.labels.template.adapter.add("text", (text, target) => {
      return target.dataItem && target.dataItem.value
        ? Number(target.dataItem.value).toLocaleString('en-IN')
        : "";
    });

    this.chart.innerRadius = am4core.percent(45);
    pieSeries.slices.template
      .cursorOverStyle = [
        {
          "property": "cursor",
          "value": "pointer"
        }
      ];

    pieSeries.alignLabels = false;
    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.radius = 7;
    pieSeries.labels.template.padding(0, 0, 0, 0);

    pieSeries.ticks.template.disabled = true;
    // console.log('this.state.totalCategory--->>>',this.state)
  // console.log('level chart data-->>>>>',this.updateChartData());
    let label1 = this.pieChart.seriesContainer.createChild(am4core.Label);
    //  label1.text = `${this.state.totalCategory}`;
    label1.horizontalCenter = "middle";
    label1.fill = am4core.color("#ff0000"); // Red color for Total
    label1.fontSize = 35;
    label1.fontWeight = 600;
    label1.dy = -30;

    this.label1 = this.pieChart.seriesContainer.createChild(am4core.Label);
    this.label1.horizontalCenter = "middle";
    this.label1.verticalCenter = "middle";
    this.label1.fill = am4core.color("#ff0000");
    this.label1.fontSize = 16;

    


    // label1.text = `20`;
  
    label1.horizontalCenter = "middle";
    label1.verticalCenter = "middle";
    label1.fill = am4core.color("#ff0000"); // Red color for Total
    label1.fontSize = 16;
    //  label1.fontWeight = 600;
    // label1.dy = -30;
    let symbol = this.pieChart.seriesContainer.createChild(am4core.Label);
    symbol.text = this.props.symbol;
    symbol.horizontalCenter = "middle";
    symbol.verticalCenter = "middle";
    symbol.fontSize = 16;
    symbol.y = -15;

    let unit = this.pieChart.seriesContainer.createChild(am4core.Label);
    unit.text = this.props.unit;
    unit.horizontalCenter = "middle";
    unit.verticalCenter = "middle";
    unit.fontSize = 12;
    unit.y = 17;


    // Start New Work




// Start New work



    // this.addCenterLabels();

    // Event handling for pie slices
    this.pieChart.events.on("ready", function () {

      const firstSlice = pieSeries.slices.getIndex(0);

      if (firstSlice) firstSlice.isActive = true;
    });

    pieSeries.slices.template.events.on("toggled", (ev) => {
      if (ev.target.isActive) {
        pieSeries.slices.each((slice) => {
          if (slice !== ev.target) {
            slice.isActive = false;
          }
        });

        const selectedData = ev.target.dataItem.dataContext;
        this.columnChart.data = selectedData.breakdown;
        columnSeries.fill = ev.target.fill;
      }
    });
    
    this.updateChartData();


  }



  updateChartData() {
    if (!this.props.graphData) return;

    const { graphData, SchemesdataFilterType } = this.props;

    const data = this.prepareChartData(graphData, SchemesdataFilterType);
    if (this.chart) {
      const beneficiariesCategory = data.find(item => item.category === "Beneficiaries");
      const stateCategory = data.find(item => item.category === "State");
      const centerCategory = data.find(item => item.category === "Center");
  // Calculate the total
  const totalCategory = [
    beneficiariesCategory?.value || 0,
    stateCategory?.value || 0,
    centerCategory?.value || 0
].reduce((acc, value) => acc + Number(value), 0);

      const pieData = data.slice(1);
     

      this.pieChart.data = data.slice(1);
      this.columnChart.data = pieData[0].breakdown;
     
    this.setState({ totalCategory }, () => {
      // console.log('totalCategory-in-->>>>>>',totalCategory);
      if (this.label1) {
        // console.log('totalCategory--2nd->>>>>>',totalCategory);
          this.label1.text = `${totalCategory.toLocaleString('en-IN')}`;
      }
  });


    }
  }



  prepareChartData(graphData, SchemesdataFilterType) {

    const datachart = graphData;
    const SchemesdataFilterType1 = SchemesdataFilterType;

    const totalvalue = Number(datachart.INVtotalAHP + datachart.INVtotalBLC + datachart.INVtotalISSR +
      datachart.INVtotalRAY + datachart.INVtotalCLSS + datachart.INVtotalARH).toFixed(0);

    const totalvaluecentral = Number(datachart.centralAssistAHP + datachart.centralAssistBLC +
      datachart.centralAssistISSR + datachart.centralAssistRAY + datachart.centralAssistCLSS + datachart.centralAssistARH).toFixed(0);

    const totalvalueState = Number(datachart.stateShareAHP + datachart.stateShareBLC + datachart.stateShareISSR +
      datachart.stateShareRAY + datachart.stateShareCLSS + datachart.stateShareARH).toFixed(0);

    const totalvaluebeneficiariesShare = Number(datachart.beneficiariesShareAHP + datachart.beneficiariesShareBLC +
      datachart.beneficiariesShareISSR + datachart.beneficiariesShareRAY + datachart.beneficiariesShareCLSS +
      datachart.beneficiariesShareARH).toFixed(0);

    return   SchemesdataFilterType1==='PMAYU' ? [
      {
        "category": "Total",
        "value": totalvalue,
        "color": am4core.color("#ff0000"), // Red color for the Total category
        "breakdown": [
          { "category": "AHP", "value": datachart.INVtotalAHP },
          { "category": "BLC", "value": datachart.INVtotalBLC },
          { "category": "ISSR", "value": datachart.INVtotalISSR },
          { "category": "ARH", "value": datachart.INVtotalARH },
          { "category": "CLSS", "value": datachart.INVtotalCLSS }
        ]
      },
      {
        "category": "Center",
        "value": totalvaluecentral,
        "color": am4core.color("#68ab77"),
        "breakdown": [
          { "category": "AHP", "value": datachart.centralAssistAHP },
          { "category": "BLC", "value": datachart.centralAssistBLC },
          { "category": "ISSR", "value": datachart.centralAssistISSR },
          { "category": "ARH", "value": datachart.centralAssistARH },
          { "category": "CLSS", "value": datachart.centralAssistCLSS },
        ]
      },
      {
        "category": "State",
        "value": totalvalueState,
        "color": am4core.color("#a0a9aa"),
        "breakdown": [
          { "category": "AHP", "value": datachart.stateShareAHP },
          { "category": "BLC", "value": datachart.stateShareBLC },
          { "category": "ISSR", "value": datachart.stateShareISSR },
          { "category": "ARH", "value": datachart.stateShareARH },
          { "category": "CLSS", "value": datachart.stateShareCLSS },
        ]
      },
      {
        "category": "Beneficiaries",
        "value": totalvaluebeneficiariesShare,
        "color": am4core.color("#6b7477"),
        "breakdown": [
          { "category": "AHP", "value": datachart.beneficiariesShareAHP },
          { "category": "BLC", "value": datachart.beneficiariesShareBLC },
          { "category": "ISSR", "value": datachart.beneficiariesShareISSR },
          { "category": "ARH", "value": datachart.beneficiariesShareARH },
          { "category": "CLSS", "value": datachart.beneficiariesShareCLSS },
        ]
      }
    ]:SchemesdataFilterType1==='both' ?[
      {
        "category": "Total",
        "value": totalvalue,
        "color": am4core.color("#ff0000"), // Red color for the Total category
        "breakdown": [
          { "category": "AHP", "value": datachart.INVtotalAHP },
          { "category": "BLC", "value": datachart.INVtotalBLC },
          { "category": "ISSR", "value": datachart.INVtotalISSR },
          { "category": "ARH", "value": datachart.INVtotalARH },
          { "category": "ISS", "value": datachart.INVtotalCLSS }
        ]
      },
      {
        "category": "Center",
        "value": totalvaluecentral,
        "color": am4core.color("#68ab77"),
        "breakdown": [
          { "category": "AHP", "value": datachart.centralAssistAHP },
          { "category": "BLC", "value": datachart.centralAssistBLC },
          { "category": "ISSR", "value": datachart.centralAssistISSR },
          { "category": "ARH", "value": datachart.centralAssistARH },
          { "category": "ISS", "value": datachart.centralAssistCLSS },
        ]
      },
      {
        "category": "State",
        "value": totalvalueState,
        "color": am4core.color("#a0a9aa"),
        "breakdown": [
          { "category": "AHP", "value": datachart.stateShareAHP },
          { "category": "BLC", "value": datachart.stateShareBLC },
          { "category": "ISSR", "value": datachart.stateShareISSR },
          { "category": "ARH", "value": datachart.stateShareARH },
          { "category": "ISS", "value": datachart.stateShareCLSS },
        ]
      },
      {
        "category": "Beneficiaries",
        "value": totalvaluebeneficiariesShare,
        "color": am4core.color("#6b7477"),
        "breakdown": [
          { "category": "AHP", "value": datachart.beneficiariesShareAHP },
          { "category": "BLC", "value": datachart.beneficiariesShareBLC },
          { "category": "ISSR", "value": datachart.beneficiariesShareISSR },
          { "category": "ARH", "value": datachart.beneficiariesShareARH },
          { "category": "ISS", "value": datachart.beneficiariesShareCLSS },
        ]
      }
    ] :[
      {
        "category": "Total",
        "value": totalvalue,
        "color": am4core.color("#ff0000"), // Red color for the Total category
        "breakdown": [
          { "category": "AHP", "value": datachart.INVtotalAHP },
          { "category": "BLC", "value": datachart.INVtotalBLC },
          { "category": "ARH", "value": datachart.INVtotalARH },
          { "category": "ISS", "value": datachart.INVtotalCLSS }
        ]
      },
      {
        "category": "Center",
        "value": totalvaluecentral,
        "color": am4core.color("#68ab77"),
        "breakdown": [
          { "category": "AHP", "value": datachart.centralAssistAHP },
          { "category": "BLC", "value": datachart.centralAssistBLC },
          { "category": "ARH", "value": datachart.centralAssistARH },
          { "category": "ISS", "value": datachart.centralAssistCLSS },
        ]
      },
      {
        "category": "State",
        "value": totalvalueState,
        "color": am4core.color("#a0a9aa"),
        "breakdown": [
          { "category": "AHP", "value": datachart.stateShareAHP },
          { "category": "BLC", "value": datachart.stateShareBLC },
          { "category": "ARH", "value": datachart.stateShareARH },
          { "category": "ISS", "value": datachart.stateShareCLSS },
        ]
      },
      {
        "category": "Beneficiaries",
        "value": totalvaluebeneficiariesShare,
        "color": am4core.color("#6b7477"),
        "breakdown": [
          { "category": "AHP", "value": datachart.beneficiariesShareAHP },
          { "category": "BLC", "value": datachart.beneficiariesShareBLC },
          { "category": "ARH", "value": datachart.beneficiariesShareARH },
          { "category": "ISS", "value": datachart.beneficiariesShareCLSS },
        ]
      }
    ]
  }



  render() {
    return (
      <div
        id={this.props.id || 'PictorialChartInvested'}
        className={this.props.id || 'PictorialChartInvested'}
        style={{
          width: '157%',
          height: this.props.height || '500px',
          position: 'relative',
          left: '-259.188px',
      
        }}
      />
    );
  }
}

export default PictorialChartInvested;
